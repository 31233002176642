import React from "react";

const Offerings = () => {
  return (
    <>
      <h1>Our Products</h1>
      <ul>
        <li>Lotteries</li>
        <li>Self Tokenization</li>
        <li>Exchange</li>
      </ul>
    </>
  );
};

export default Offerings;
