import React from "react";
import Layout from "../components/Layout";

const Notifications = () => {
  return (
    <Layout>
      <></>
    </Layout>
  );
};

export default Notifications;
